import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, Button, Menu, MenuItem } from "@mui/material";
import React from "react";
import { useAppSelector } from "../../app/hooks";
import {
  Environments,
  getEnvironmentName,
  getEnvKey,
} from "../../data/AxiosConfig";
import { selectSession } from "../../features/session/sessionSlice";

export const EnvSwitcher = ({ expanded }: { expanded: boolean }) => {
  const { game_id } = useAppSelector(selectSession);
  const currentEnv = getEnvironmentName();
  const envKey = getEnvKey();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return expanded ? (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      mt={1}
      mb={3}
    >
      <Button
        variant="contained"
        color={envKey === Environments.LIVE ? "error" : "primary"}
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {currentEnv}
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          disabled={envKey === Environments.STAGING}
          color="primary"
          onClick={() =>
            (window.location.href = `https://newton.servers.kwalee.com?gameId=${game_id}`)
          }
        >
          UAT
        </MenuItem>
        <MenuItem
          disabled={envKey === Environments.LIVE}
          color="danger"
          onClick={() =>
            (window.location.href = `https://live-newton.servers.kwalee.com?gameId=${game_id}`)
          }
        >
          LIVE
        </MenuItem>
      </Menu>
    </Box>
  ) : null;
};
