import { Box } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { selectSession } from "../../features/session/sessionSlice";
import { useViewport } from "../../utils/useViewport";
import { SearchBar } from "../SearchBar";

interface SearchProps {
  expanded: boolean;
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  setPlaceholder: (placeholder: string) => void;
  placeholder: string;
  setSearchFocus: (bool: boolean) => void;
}

export const Search = ({
  expanded,
  searchTerm,
  setSearchTerm,
}: SearchProps) => {
  const { session_id: sessionId } = useSelector(selectSession);
  const isLoggedIn = !!sessionId;

  const searchRef = useRef<HTMLInputElement>();

  useEffect(() => {
    // Listen for search bar focus
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.code === "Slash") {
        searchRef.current?.focus();
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return function cleanup() {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const { width } = useViewport();
  const isMobile = width < 769;

  return (
    <Box display={isMobile && !expanded ? "none" : "block"}>
      {isLoggedIn && (
        <SearchBar
          sx={{
            "& .MuiInputBase-input": {
              color: "white",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "red",
            },
          }}
          value={searchTerm}
          setValue={setSearchTerm}
        />
      )}
    </Box>
  );
};
