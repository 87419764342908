interface ImageProps {
  gameId: string;
  baseURL: string;
  style: any;
}

const ImageFallback = ({ gameId, baseURL, style }: ImageProps) => {
  return (
    <img
      src={baseURL.replace("{gameId}", `${gameId}.jpg`)}
      alt={gameId}
      style={style}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null;
        currentTarget.src = baseURL.replace("{gameId}", "no-icon.png");
      }}
    />
  );
};

export default ImageFallback;
