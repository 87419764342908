export function recursiveSearch(
  obj: any,
  term?: string,
  parentMatch?: boolean
): any {
  if (!term) {
    return undefined;
  }

  let newObj: any;

  // Check if match search term
  if (typeof obj === "object" && obj !== null) {
    newObj = { ...obj };

    if (Array.isArray(obj)) {
      newObj = [...obj];
      newObj = obj.map((el: any) => recursiveSearch(el, term));
    }

    if (obj?.hasOwnProperty("name")) {
      let match = parentMatch ?? false;
      if (parentMatch) {
        newObj["__child_match__"] = true;
      }

      if (obj.name.toLowerCase().includes(term.toLowerCase())) {
        newObj["__match__"] = true;
        // Match must be inherited by all children of this value
        match = true;
      } else {
        newObj["__match__"] = false;
      }

      if (obj.hasOwnProperty("elements")) {
        newObj.elements = obj.elements?.map((el: any) =>
          recursiveSearch(el, term, match)
        );
      }
    }
  } else {
    newObj = { ...obj };
  }

  return newObj;
}

export function addParentMatches<T>(obj: any): T {
  if (typeof obj === "object" && obj !== null) {
    if (Array.isArray(obj)) {
      obj = obj.map((el) => addParentMatches(el));
    }

    if (obj?.hasOwnProperty("name")) {
      obj["__parent_match__"] = hasChildMatch(obj) ?? false;

      if (obj.hasOwnProperty("elements")) {
        obj.elements = obj.elements?.map((el: any) => addParentMatches(el));
      }
    }
  }

  return obj;
}

export function hasChildMatch(obj: any): boolean {
  let matchArray: boolean[] = [];
  if (typeof obj === "object") {
    if (Array.isArray(obj)) {
      matchArray = obj.map((el) => hasChildMatch(el));
    }

    if (obj.hasOwnProperty("name")) {
      if (obj.__match__) {
        matchArray.push(true);
      } else if (obj.hasOwnProperty("elements")) {
        matchArray.push(hasChildMatch(obj.elements));
      }
    }
  }

  return matchArray.includes(true);
}
