import { Typography } from "@mui/material";
import React from "react";

interface HeadingProps {
  children: React.ReactNode;
}

export const Paragraph = ({ children }: HeadingProps) => {
  return <Typography component="p">{children}</Typography>;
};
